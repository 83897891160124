
import { Component, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-module-decorators';
import Input from '@/components/ui/input/Input.vue';
import Button from '@/components/ui/button/Button.vue';
import Notify from '@/services/helpers/notify';
import ApiClient from '@/api/api.client';
import Cookies from 'js-cookie';
import { HEADERS } from '@/services/helpers/constants';
import ROUTES from '@/router/ROUTES';
import GeneralModule from '@/store/modules/general/GeneralModule';

@Component({
    name: 'Reset',
    components: {
        Input,
        Button,
    },
})
export default class PasswordReset extends Vue {
    public password = '';
    public isPasswordValid: boolean | null = null;
    public authToken: string;
    public hashValid = false;


    get canSubmit(): boolean {
        return !!this.password && this.isPasswordValid;
    }

    onPasswordChange(value: string) 
    {
        this.password = value.trim();
        if (this.password.length >= 8 && /[0-9]/.test(value)) 
        {
            this.isPasswordValid = true;
        }
        else
        {
            this.isPasswordValid = false;
        }
    }

    beforeMount()
    {
        this.checkHash();
    }
    async checkHash()
    {
        const hash = this.$route.hash.slice(1, this.$route.hash.length);
        this.hashValid = await (await GeneralModule.checkHash(hash)).success

        if(hash.length && !this.hashValid)
        {
            this.$router.push('/login')
        }
    }
    checkIfValid()
    {
        if(!this.isPasswordValid)
        {
            this.isPasswordValid = null;
        }
    }
    passwordValidator() {
        this.isPasswordValid = this.validatePassword(this.password);
    }

    /* Валидируем - минимум:
        8 символов, 
        1 ЗАГЛАВНАЯ,
        1 строчная,
        1 цифра,
        1 спецсимвол @$!%*#?&
    */
    validatePassword(value: string) {
        const russianRegex = /[а-яА-ЯЁё]/;
        
        if (russianRegex.test(this.password)) {
            Notify.error('Только латинские символы');
            return false;
        };
        if (this.password.length < 8) {
            Notify.error(this.$tc('Recovery.Errors.Length'));
            return false;
        };
        const number = /[0-9]/.test(value);
        if (number === false) {
            Notify.error(this.$tc('Recovery.Errors.Number'));
        }

        return number;
    }

    async onSubmit() {
        this.passwordValidator();
        if (this.canSubmit && this.isPasswordValid) {
            const hash = this.$route.hash.slice(1);
            ApiClient.recovery.restore(hash, this.password)
            .then(
                res => {
                    if(res.status === 200) {
                        this.setAuthToken(res.data.token);
                        this.$router.push(ROUTES.HOME);
                    }
                },
            );
        }
    }

    @Mutation
    public setAuthToken(token: string) {
        this.authToken = token;
        Cookies.set(HEADERS.AUTH_HEADER, token);
    }
}
